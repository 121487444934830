<template>
    <v-main>
        <v-row class="mt-5">
            <v-col v-for="(item,index) in cantidad" :key="index" >
                <v-skeleton-loader
                class="mx-auto"
                width="300"
                type="card"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-main>
</template>
<script>
export default {
    props: {
        cantidad: {
            type: Number,
            default: 3
        }
    }
}
</script>