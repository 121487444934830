<template>
    <v-toolbar dark rounded="" color="indigo darken-4"> 
            <v-btn @click="$emit('retroceder')" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class=" headline font-weight-light">{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="accion"></slot>
    </v-toolbar>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
  //props: ['title','actions','revisar_sector']
}
</script>