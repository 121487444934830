<template>
  <v-sheet rounded="" height="" elevation="5"  max-width="250"
    max-height="300">
    <v-container>
      <v-row>
        <v-col>
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  rounded=""
                  color="blue darken-4"
                  elevation="5"
                  class="mt-n8"
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('revisar', informacion)"
                >
                  <v-row align="center" justify="center">
                    <v-col>
                      <div
                        v-text="informacion.nombre"
                        class="title font-weight-light white--text text-center"
                      ></div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </template>
              <span>Revisar Sector</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-col>
          <v-img
            height="120"
            max-width="95"
            contain
            class="mx-auto"
            :src="imagen"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row class="mb-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              fab
              class="ml-4 blue-grey lighten-5"
              @click="$emit('editar', informacion)"
              elevation=""
              color="yellow darken-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar Sector</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              fab
              class="mr-4 blue-grey lighten-5"
              @click="$emit('eliminar', informacion)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Eliminar Sector</span>
        </v-tooltip>
      </v-row>
    </v-container>
  </v-sheet>
  <!-- <v-card elevation="4" class="mx-auto" width="300" height="300">
    <v-card-text>
      <v-card
        @click="$emit('revisar', informacion)"
        dark
        color="indigo"
        elevation="4"
        class="ma-n7"
        max-width="95"
      >
        <v-card-text>
          <h3 style="text-align: center">Revisar</h3>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text>
      <h3 style="text-align: center">{{ informacion.nombre }}</h3>
      <v-img height="150" contain class="mx-auto" :src="imagen"> </v-img>
    </v-card-text>
    <v-card-actions class="align-end">
      <v-btn @click="$emit('editar', informacion)" dark small fab color="green">
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('eliminar', informacion)" dark small fab color="red">
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card> -->
</template>
<script>
export default {
  props: ["imagen", "informacion"],
  data() {
    return {};
  },
  methods: {},
};
</script>