<template>
  <EstructuraBasica class="mt-n15">
    <template v-slot:main>
      <v-container fluid class="" id="toolbar_title">
      <ToolbarTitle
        v-on:crear_usuario="crear_usuario"
        :revisar_sector="revisar_sector"
        v-on:retroceder="
          $router.go(-1);
          revisar_sector = false;
          title_sector = 'Áreas';
        "
        :title="title_sector"
        v-on:crear="
          dialog.abrir = true;
          dialog.crear = true;
        "
      >
        <template slot="accion" v-if="revisar_sector==true">
          <PermisosUsuario :sector="sector" />
        </template>
      </ToolbarTitle>
    </v-container>
    <v-container fluid>
      <v-container
        v-if="revisar_sector == false"
        class="mt-4"
      >
        <v-row id="toolbar">
          <v-col cols="12" sm="8">
            <v-text-field
              rounded
              dense
              filled
              label="Buscar Area"
              v-model="search"
              append-icon="mdi-magnify"
              class="mb-6"
            >
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" >
            <v-btn
             block
              text
              color="indigo darken-4"
              @click="
                dialog.abrir = true;
                dialog.crear = true;
              "
            >
              Crear Nueva Area
            </v-btn>
          </v-col>
        </v-row>

        <v-row id="formulario">
          <v-col id="sectores">
            <v-row>
              <v-col
                v-for="(sector, index) in itemsFilter"
                :key="index"
                cols="12"
                sm="6"
                md="4"
                
              >
                <Elementos
                  class="mx-auto"
                  v-on:editar="
                    dialog.abrir = true;
                    dialog.editar = true;
                    sector_editar = sector;
                    sector_editar.sector_actual = sector.nombre;
                  "
                  :informacion="sector"
                  v-on:eliminar="
                    dialogEliminar = true;
                    sector_eliminar = sector;
                  "
                  v-on:revisar="revisar"
                  :imagen="img"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-row v-else-if="revisar_sector == true" class="mt-3">
        <router-view></router-view>
      </v-row>
      <v-row class="" v-if="load != false">
        <Skeletons />
      </v-row>
      <v-row v-else-if="sectores.length == 0 && load==false" class="mt-3">
        <div v-text="`No hay elementos a mostrar por favor crea pulsando del boton de Crear Nueva area`" class=" mx-auto headline text-center font-weight-light"></div>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog.crear" max-width="500">
      <v-sheet>
        <v-container>
          <v-row>
            <v-col>
              <v-sheet
                rounded=""
                elevation="5"
                color="blue darken-4"
                dark
                class="mt-4"
              >
                <v-row>
                  <v-col>
                    <div
                      v-text="'Crear Nueva area'"
                      class="headline font-weight-light text-center"
                    ></div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img
                class="ma-5"
                contain
                height="70"
                :src="require('../../assets/img.png')"
              ></v-img>
              <v-text-field
                v-model="nuevo_sector"
                color="indigo"
                rounded
                filled
                dense
                label="Nombre del area"
              ></v-text-field>
              <v-btn
                block
                :disabled="nuevo_sector == null || nuevo_sector == ''"
                style="color: white"
                color="indigo"
                @click="crear_sector()"
              >
                Crear
                <v-icon> mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="dialog.editar" max-width="500">
      <v-sheet>
        <v-container>
          <v-row>
            <v-col>
              <v-sheet
                rounded=""
                elevation="5"
                color="indigo darken-4"
                dark
                class="mt-4"
              >
                <v-row>
                  <v-col>
                    <div
                      v-text="'Editar Sector ' + sector_editar.sector_actual"
                      class="headline font-weight-light text-center"
                    ></div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- Se valida si se esta creando o editando -->

              <v-img
                class="ma-5"
                contain
                height="70"
                :src="require('../../assets/img.png')"
              ></v-img>
              <v-text-field
                v-model="sector_editar.nombre"
                color="indigo"
                rounded
                filled
                dense
                label="Nombre del area"
              ></v-text-field>

              <v-btn
                block
                :disabled="
                  sector_editar.nombre == null || sector_editar.nombre == ''
                "
                style="color: white"
                color="green"
                @click="editar()"
              >
                Editar
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="dialogEliminar" max-width="600">
      <v-card class="mx-auto" max-width="570">
        <v-toolbar dark color="indigo darken-4">
          <v-toolbar-title>
            Eliminando area: {{ sector_eliminar.nombre }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEliminar = false" fab icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="6">
            <v-container fluid class="fill-height ml-3">
              <v-row align="center" justify="center">
                <v-img
                  class="ma-5"
                  contain
                  height="70"
                  :src="require('../../assets/img.png')"
                ></v-img>

                <v-btn
                  block
                  style="color: white"
                  color="red"
                  @click="eliminar()"
                >
                  Eliminar
                  <v-icon> mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-col>
          <v-col class="mx-5" cols="5">
            <v-card
              dark
              elevation="8"
              color="red lighten-1"
              class="ma-n18 mx-auto"
              height="300"
              width="240"
            >
              <v-container fluid class="fill-height">
                <v-row align="center" justify="center">
                  <h3 style="text-align: center">
                    Estas seguro que desear eliminar el area?
                  </h3>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- El contenido del dialogo se puede hacer un compoennte -->
    <!-- este dialogo ya no es funcional -->
    <v-dialog max-width="600">
      <v-card class="mx-auto" max-width="570">
        <v-toolbar dark color="black">
          <v-toolbar-title>
            {{
              dialog.crear ? "Nuevo sector" : "Editar sector"
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="dialog.abrir = false" fab icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="6">
            <v-container fluid class="fill-height ml-3">
              <v-row v-if="dialog.crear" align="center" justify="center">
                <v-img
                  class="ma-5"
                  contain
                  height="70"
                  :src="require('../../assets/img.png')"
                ></v-img>
                <v-text-field
                  v-model="nuevo_sector"
                  color="indigo"
                  rounded
                  filled
                  dense
                  label="Nombre del area"
                ></v-text-field>
                <v-btn
                  block
                  :disabled="nuevo_sector == null || nuevo_sector == ''"
                  style="color: white"
                  color="indigo"
                  @click="crear_sector()"
                >
                  Crear
                  <v-icon> mdi-check</v-icon>
                </v-btn>
              </v-row>

              <v-row v-else align="center" justify="center">
                <v-img
                  class="ma-5"
                  contain
                  height="70"
                  :src="require('../../assets/img.png')"
                ></v-img>
                <v-text-field
                  v-model="sector_editar.nombre"
                  color="indigo"
                  rounded
                  filled
                  dense
                  label="Nombre del area"
                ></v-text-field>

                <v-btn
                  block
                  :disabled="
                    sector_editar.nombre == null || sector_editar.nombre == ''
                  "
                  style="color: white"
                  color="green"
                  @click="editar()"
                >
                  Editar
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-col>
          <v-col class="mx-5" cols="5">
            <v-card
              dark
              elevation="8"
              color="indigo lighten-1"
              class="ma-n18 mx-auto"
              height="300"
              width="240"
            >
              <v-container fluid class="fill-height">
                <v-row align="center" justify="center">
                  <h3 style="text-align: center">
                    para que se hace esto Aqui ira un texto descriptivo que
                    indique para que se hace esto Aqui ira un texto descriptivo
                    que indique para que se hace estoAqui ira un texto
                    descriptivo que indique para que se hace esto
                  </h3>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    </template>
  </EstructuraBasica>
</template>
<script>
const axios = require("axios").default;
import ToolbarTitle from "../../components/admin/ToolbarTitle";
import Elementos from "../../components/admin/Elementos";
import Skeletons from "../../components/admin/Skeletons";
import PermisosUsuario from '../../components/admin/PermisosUsuario'
import mixin_buscador from "../../mixins/buscador";
export default {
  components: {
    ToolbarTitle,
    Elementos,
    Skeletons,
    PermisosUsuario,
  },
  data() {
    return {
      img: require("../../assets/sectores.png"),
      dialog: {
        abrir: false,
        crear: null,
        editar: false, //True para crear false para editar
      },
      nuevo_sector: null,
      revisar_sector: false,
      title_sector: "Áreas",
      sectores: [],
      load: null,
      sector_editar: {
        nombre: null,
        objectId: "",
        sector_actual: "",
      },
      dialogEditar: false,

      sector_eliminar: {
        nombre: null,
        objectId: "",
      },
      dialogEliminar: false,
      pagination: {
        info_page: [],
      },
      page: 1,
      sector: {},
    };
  },
  mixins: [mixin_buscador],
  watch: {
    page: function () {
      console.log(this.page);
      this.pagination.info_page = this.sectores[this.page - 1];
      console.log(this.pagination.info_page);
    },
  },
  computed: {},
  async created() {
    try {
      this.$store.commit("auth", "parse");
      let response = await axios.get(
        " https://audidoc.educandote.co/parse/classes/prueba01Sector",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
            "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
            Authorization: this.$store.state.header,
          },
        }
      );
     
      console.log(response.data.results);
      if (typeof response.data != "undefined") {
        this.items = response.data.results;
        this.itemsFilter = response.data.results;
        this.sectores = response.data.results;
      }
    } catch (error) {
      console.log(error);
    
    }
    this.load = false;
  },
  methods: {
    crear_usuario() {
      console.log("crear usuario");
      this.$store.state.admin.actualizarUsuariosSector = Math.random();
    },
    async crear_sector() {
      console.log("crear");
      this.$store.commit("auth", "parse");
      console.log(this.$store.state.header);

      try {
        let response = await axios.post(
          " https://audidoc.educandote.co/parse/classes/prueba01Sector",
          {
            nombre: this.nuevo_sector,
            tipos_documentales: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response.data);
        this.sectores.unshift({
          nombre: this.nuevo_sector,
          empleados_sector: [],
          tipos_documentales: [],
          objectId: response.data.objectId,
        });
       
       
        this.$store.commit(
          "exitoso",
          "El Area " + this.nuevo_sector + " ha sido creato con exito "
        );
        this.nuevo_sector = null;
        this.dialog.abrir = false;
        this.dialog.crear = false;
      } catch (error) {
        console.log(error);
        //Mensaje de error
      }
    },
    async editar() {
      console.log("editando");
      console.log(this.sector_editar);

      try {
        this.$store.commit("auth", "parse");
        let response_sectores = await axios.put(
          " https://audidoc.educandote.co/parse/classes/prueba01Sector/" +
            this.sector_editar.objectId,
          {
            nombre: this.sector_editar.nombre,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response_sectores);

        for (let index = 0; index < this.sectores.length; index++) {
          if (this.sectores[index].objectId == this.sector_editar.objectId) {
            this.sectores[index].nombre = this.sector_editar.nombre;
          }
        }
        this.$store.commit(
          "exitoso",
          "La edicion del sector " +
            this.sector_editar.nombre +
            " ha sido exitosa"
        );
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error editando el sector " + this.sector_editar.nombre+ " "+error.message
        );
      }
      //----------------------------
      //Aqui se cargan los usuarios para despues cambiarles el nombre del sector
      // Cambiar esto de aqui
      //Hacemos un cambio local para no hacer un refresh a la data (La data se actualiza al entrar neuvamente en la vista)
      this.dialog.abrir = false;
      this.dialog.crear = null;
      this.dialog.editar = false;
    },
    //Funcion eliminar
    async eliminar() {
      console.log("eliminar");
      console.log(this.sector_eliminar);
     

      try {
        let response = await axios.delete(
          " https://audidoc.educandote.co/parse/classes/prueba01Sector/" +
            this.sector_eliminar.objectId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response);
        let index = this.sectores.indexOf(this.sector_eliminar);
        console.log(index);
        this.sectores.splice(index, 1);

        //Se elimann los usuarios que tengan el sector tal
               let sector_eliminar = JSON.stringify({"sector": this.sector_eliminar.objectId,});
                sector_eliminar = encodeURIComponent(sector_eliminar);
        try {
          this.$store.commit("auth", "parse");
          let res_1 = await axios.get(
            " https://audidoc.educandote.co/parse/classes/prueba03Usuarios?where="+sector_eliminar,

            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log("Todos los usuarios antes del cambio");
          console.log(res_1.data.results);

          for (let index = 0; index < res_1.data.results.length; index++) {
              try {
                //Se eliminan el usuario del sector desde Usuarios una vez ya se cargaron los usuarios
                this.$store.commit("auth", "parse");
                let res_2 = await axios.delete(
                  " https://audidoc.educandote.co/parse/classes/prueba03Usuarios/" +
                    res_1.data.results[index].objectId,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                      "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                      Authorization: this.$store.state.header,
                    },
                  }
                );
                console.log(res_2);
                this.$store.commit("auth", "parse");
                //Una vez encontrado se elimna
                let res_4 = await axios.delete(
                  "https://audidoc.educandote.co/parse/classes/prueba01Login/" +
                    res_1.data.results[index].userId,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                      "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                      Authorization: this.$store.state.header,
                    },
                  }
                );
                console.log(res_4);
              } catch (error) {
                console.log(error);
              }
            
          }
        } catch (error) {
          console.log(error);
        }

        this.$store.commit(
          "exitoso",
          "El sector " +
            this.sector_eliminar.nombre +
            " ha sido eliminado de manera exitosa"
        );
      } catch (error) {
        this.$store.commit(
          "error",
          "Ha ocurrido un error eliminando el sector " +
            this.sector_eliminar.nombre +
            " intenta mas tarde "+error.message
        );
        console.log(error);
      }

      this.dialogEliminar = false;
    },
    revisar(sector) {
      console.log(sector);
      this.$router.push({
        name: "sector",
        params: { id: sector.nombre, data: sector },
      });
      console.log("revisar");
      this.revisar_sector = true;
      this.title_sector = "Usuarios del Area " + "'" + sector.nombre + "'";
      this.sector = sector
      //Redirect a la ruta hija
    },
  },
};
</script>