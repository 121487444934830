var buscador = {
    data(){
        return{
            search: null,
            items: [],
            itemsFilter: [],
        }
    },
    watch: {
        search (val) {
          val = val.toLowerCase()
    
          this.itemsFilter = this.items.filter( item => item.nombre.toLowerCase().indexOf(val) !== -1 )
    
        },
    },
}

export default buscador 

