var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"rounded":"","height":"","elevation":"5","max-width":"250","max-height":"300"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"mt-n8",attrs:{"rounded":"","color":"blue darken-4","elevation":"5"},on:{"click":function($event){return _vm.$emit('revisar', _vm.informacion)}}},'v-sheet',attrs,false),on),[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('div',{staticClass:"title font-weight-light white--text text-center",domProps:{"textContent":_vm._s(_vm.informacion.nombre)}})])],1)],1)]}}])},[_c('span',[_vm._v("Revisar Sector")])])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',[_c('v-img',{staticClass:"mx-auto",attrs:{"height":"120","max-width":"95","contain":"","src":_vm.imagen}})],1)],1),_c('v-row',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 blue-grey lighten-5",attrs:{"text":"","small":"","fab":"","elevation":"","color":"yellow darken-3"},on:{"click":function($event){return _vm.$emit('editar', _vm.informacion)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v("Editar Sector")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 blue-grey lighten-5",attrs:{"text":"","small":"","fab":"","color":"red"},on:{"click":function($event){return _vm.$emit('eliminar', _vm.informacion)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Eliminar Sector")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }